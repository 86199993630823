import Vue from 'vue'
import Vuex from 'vuex'
import { MessageBox, Message, Notification, Loading } from "element-ui";
import Swal from "sweetalert2";
import axios from 'axios'
import router from '@/router'

require('dotenv').config()

//modules
import {redirect} from './redirect/index'
import {robot} from './robots/index'
import {seo_config} from './seo_conifg/index'
import {vendor_module} from './vendor_module/index'
import {settings_module} from './settings_module/index'
import { home_banner_module } from './home-banner/index'
import { gallery_module } from './gallery/index'
import { themes_module } from './store_themes/index'
import { store_categories_module } from './store_categories_module/index'
import { subscription_module } from './subscription_module/index'
import { dashboard_stats } from './stats_modules/dashboard_stats'
import { dashboard_order_stats } from './stats_modules/dashboard_order_stats'
import { dashboard_product_stats } from './stats_modules/dashboard_product_stats'
import { main_stats } from './stats_modules/main_stats'
import { orders_module } from './orders/index'
import { products_module } from './products/index'
import { productCategories_module } from './productCategories/index'
import { brands_module } from './brands/index'
import { customers_module } from './customers/index'
import { shipping_module } from './shipping/index'
import { coupons_module } from './coupons/index'
import { blogs_module } from './blogs/index'
import { blogCategories_module } from './blog-categories/index'
import { content_blocks } from './contentBlocks/index'
import { expenses_module } from './expenses/index'
import { accounts_module } from './accounts/index'
import { bills_module } from './bills/index'
import { bar_graph_module } from './graphs/barGraph'
import { line_graph_module } from './graphs/lineGraph'
import { pie_graph_module } from './graphs/doughnutGraph'
import { notifications_module } from './notifications/index'
import { communication_module } from './communication/index'
import { support_members_module } from './chat-support/index'
import { page_details_module } from './page_details/index'
import { no_index_urls_module } from './no_index_urls/index'
import { admin_users_module } from './admin-users/index'
import { countries_module } from './countries/index'
import { navigations_module } from './navigation_modules/navigations'
import { faqs_module } from './faqs/index'
import { form_summary_module } from './form_summary/index'
import { contacts_module } from './crm/contacts/index'
import { groups_module } from './crm/groups/index'
import { email_templates_module } from './crm/email-templates/index'
import { emails_module } from './crm/emails/index'
import { product_bundles_module } from './product-bundles/index'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    user: null,
    username:null,
    roles:[],
    isPending:false,
    session: {
      isExpired: false,
      message: ''
    },
    // seo_config: null
  },
  mutations: {
    loggedIn(store, payload){

      store.user = null;
      store.roles = [];

      store.loggedIn = true;
      store.user = payload;

      store.username = store.user.first_name + ' ' + store.user.last_name;

      if(payload.roles){

        payload.roles.forEach(role=>{

          let obj = {
            name: role
          }

          store.roles.push(obj);

        });

      }

    },
    loggedOut(store){

      store.loggedIn = false;
      store.user = null;
      store.username = null;
      store.roles = [];

    },
    sessionExpired(store, payload){

      store.session.isExpired = true;
      store.session.message = payload;

    },
    resetExpire(store){

      store.session.isExpired = false;
      store.session.message = '';

    },
    // updateSeoConfig(store,payload){
    //   console.log("updateSeoConfig called");
    //   store.seo_config = payload;
    // }
  },
  actions: {
    authLoggedIn({commit},payload){

      commit('loggedIn',payload);

    },
    authLoggedOut({commit}){

      commit('loggedOut');

    },
    async logout({commit}){

      MessageBox.confirm(
        "Do you really want to logout?", 
        "Warning", 
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      ).then(async () => {

        let loader = Loading.service({
          text: "The user is being logged out. Please wait!",
          fullscreen: true,
        });

        this.state.isPending = true;
        try{
          let res = await axios.post(`${process.env.VUE_APP_BASE_URL}/logout`,{},{
            headers:{
              Authorization : `Bearer ${this.state.user.token}`
            }
          });
          if(res.data.status_code == "1000"){

            this.state.isPending = false;
  
            Notification.success({
              title: 'Success',
              message: res.data.message,
            });
  
            Vue.$cookies.remove("meri_web_shop.current_user");
  
            commit('loggedOut');
  
            router.push({ path: "/login" });
            
            for(const [key,value] of Object.entries(this.state)){
  
              if(key != "loggedIn" && key != "user" && key != "roles" && key != "session" && key != "seo_config" && key != "isPending" && key != "username" ){
  
                commit(`${key}/CLEAR_MODULE`);
                
              }
  
            }
  
          }
        }catch(error){

          this.state.isPending = false;

          Message({
            type: "error",
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          loader.close();
            
        }

      }).catch(()=>{

        this.state.isPending = false;

      });
    },
    async logoutWithoutConfirmation({commit},{url}){
      
      let loader = Loading.service({
        text: "The user is being logged out. Please wait!",
        fullscreen: true,
      });

      let token = ''

      if(this.state.user && this.state.user.token){

        token = this.state.user.token

      }else{

        let user = Vue.$cookies.get('meri_web_shop.current_user');

        token = user.token

      }

      this.state.isPending = true;
      try{
        let res = await axios.post(`${process.env.VUE_APP_BASE_URL}/logout`,{},{
          headers:{
            Authorization : `Bearer ${token}`
          }
        });
        if(res.data.status_code == "1000"){
          
          this.state.isPending = false;
  
          Notification.success({
            title: 'Success',
            message: res.data.message,
          });
  
          Vue.$cookies.remove("meri_web_shop.current_user");
  
          commit('loggedOut');

          if(url){

            router.push({ path: url });

          }else{

            router.push({ path: "/login" });

          }
          
          setTimeout(()=>{
            for(const [key,value] of Object.entries(this.state)){

              if(key != "loggedIn" && key != "user" && key != "roles" && key != "session" && key != "seo_config" && key != "isPending" && key != "username" ){

                commit(`${key}/CLEAR_MODULE`);

              }

            }
          },500)
  
        }
      }catch(error){

        this.state.isPending = false;

        Message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }finally{

        loader.close();

      }

    }
  },
  getters:{
    logoutIsPending(state){

      return state.isPending;
      
    }
  },
  modules: {
    redirect: {
      namespaced: true,
      ...redirect
    },
    robot:{
      namespaced: true,
      ...robot
    },
    seo_config:{
      namespaced: true,
      ...seo_config
    },
    vendor_module:{
      namespaced: true,
      ...vendor_module
    },
    settings_module:{
      namespaced: true,
      ...settings_module
    },
    home_banner_module:{
      namespaced: true,
      ...home_banner_module
    },
    gallery_module:{
      namespaced: true,
      ...gallery_module
    },
    themes_module:{
      namespaced: true,
      ...themes_module
    },
    store_categories_module:{
      namespaced: true,
      ...store_categories_module
    },
    subscription_module:{
      namespaced: true,
      ...subscription_module
    },
    dashboard_stats:{
      namespaced: true,
      ...dashboard_stats
    },
    main_stats:{
      namespaced: true,
      ...main_stats
    },
    dashboard_order_stats:{
      namespaced: true,
      ...dashboard_order_stats
    },
    dashboard_product_stats:{
      namespaced: true,
      ...dashboard_product_stats
    },
    orders_module:{
      namespaced: true,
      ...orders_module
    },
    products_module:{
      namespaced: true,
      ...products_module
    },
    productCategories_module:{
      namespaced: true,
      ...productCategories_module
    },
    brands_module:{
      namespaced: true,
      ...brands_module
    },
    customers_module:{
      namespaced: true,
      ...customers_module
    },
    shipping_module:{
      namespaced: true,
      ...shipping_module
    },
    coupons_module:{
      namespaced: true,
      ...coupons_module
    },
    blogs_module:{
      namespaced: true,
      ...blogs_module
    },
    blogCategories_module:{
      namespaced: true,
      ...blogCategories_module
    },
    content_blocks:{
      namespaced: true,
      ...content_blocks
    },
    expenses_module:{
      namespaced: true,
      ...expenses_module
    },
    accounts_module:{
      namespaced: true,
      ...accounts_module
    },
    bills_module:{
      namespaced: true,
      ...bills_module
    },
    bar_graph_module:{
      namespaced: true,
      ...bar_graph_module
    },
    line_graph_module:{
      namespaced: true,
      ...line_graph_module
    },
    pie_graph_module:{
      namespaced: true,
      ...pie_graph_module
    },
    notifications_module:{
      namespaced: true,
      ...notifications_module
    },
    communication_module:{
      namespaced: true,
      ...communication_module
    },
    support_members_module:{
      namespaced: true,
      ...support_members_module
    },
    page_details_module:{
      namespaced: true,
      ...page_details_module
    },
    no_index_urls_module:{
      namespaced: true,
      ...no_index_urls_module
    },
    admin_users_module:{
      namespaced: true,
      ...admin_users_module
    },
    countries_module:{
      namespaced: true,
      ...countries_module
    },
    navigations_module:{
      namespaced: true,
      ...navigations_module
    },
    faqs_module:{
      namespaced: true,
      ...faqs_module
    },
    form_summary_module:{
      namespaced: true,
      ...form_summary_module
    },
    contacts_module:{
      namespaced: true,
      ...contacts_module
    },
    groups_module:{
      namespaced: true,
      ...groups_module
    },
    email_templates_module:{
      namespaced: true,
      ...email_templates_module
    },
    emails_module:{
      namespaced: true,
      ...emails_module
    },
    product_bundles_module:{
      namespaced: true,
      ...product_bundles_module
    },
  }
})